import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { Stack, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import { PrimaryButton, SecondaryButton, NumberInput, BannerNotification } from "@hm-group/fabric-components/brand/hm";
import { CopyIcon, RefreshIcon } from "@hm-group/fabric-icons";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import PageContainer from '../../components/PageContainer';
import LabelPreview from './LabelPreview';
import AddSampleFieldsWithPN from './AddSampleFieldsWithPN';
import AddSampleFieldsWithoutPN from './AddSampleFieldsWithoutPN';

const PrintLabel = ({ selectedShowroomID }) => {
  const initialSampleDataWithPN = {
    productNumber: '',
    season: '',
    sectionName: '',
    departmentName: '',
    price: '',
    currencyCode: '',
    sizes: '',
    inShopWeek: '',
    composition: '',
    information: '',
    showroomId: selectedShowroomID,
  }

  const initialSampleDataWithoutPN = {
    sectionName: '',
    departmentName: '',
    information: '',
    showroomId: selectedShowroomID,
  }

  const [sampleData, setSampleData] = useState(initialSampleDataWithPN);
  const [productInfoLoaded, setProductInfoLoaded] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [numberOfSamples, setNumberOfSamples] = useState(1);
  const [numberOfSaves, setNumberOfSaves] = useState(0);
  const [saveFunctionalityDisabled, setSaveFunctionalityDisabled] = useState(true);
  const [showScreen, setShowScreen] = useState('withProductNumber');
  const [barcode, setBarcode] = useState();
  const [tempPDF, setTempPDF] = useState();
  const [isPrintingSamples, setIsPrintingSamples] = useState(false);

  const printRef = React.useRef();
  const { authAxiosRequest } = useAuthAxiosRequest();

  const maxTextLengths = {
    composition: 70,
    information: showScreen === 'withProductNumber' ? 70 : 150,
  }

  const resetSampleData = () => {
    if (showScreen === 'withProductNumber') {
      setSampleData(initialSampleDataWithPN);
      setProductInfoLoaded(false);
    } else {
      setSampleData(initialSampleDataWithoutPN);
      setDepartmentList([]);
    }
    setNumberOfSamples(1);
  }

  const resetPrintingData = () => {
    setBarcode();
    setNumberOfSamples(1);
    setNumberOfSaves(0);
    setTempPDF();
  }

  // Disable/enable save button
  useEffect(() => {
    if (showScreen === 'withProductNumber') {
      (!sampleData.productNumber || (sampleData.composition && sampleData.composition.length > maxTextLengths.composition)
        || sampleData.information.length > maxTextLengths.information
        || numberOfSamples < 1 || numberOfSamples > 10
      )
        ? setSaveFunctionalityDisabled(true)
        : setSaveFunctionalityDisabled(false);
    } else {
      (!sampleData.sectionName || !sampleData.departmentName
        || sampleData.information.length > maxTextLengths.information)
        || numberOfSamples < 1 || numberOfSamples > 10
        ? setSaveFunctionalityDisabled(true)
        : setSaveFunctionalityDisabled(false);
    }
  },[sampleData, numberOfSamples])

  const checkForMultiplePrints = (pdf) => {
    if(numberOfSamples > numberOfSaves) {
      saveSample();
    } else {
      // ----------------- PDF WITH MULTIPLE LABELS - FINAL PRINT
      window.open(pdf.output("bloburl"));

      resetPrintingData();
      resetSampleData();
      setIsPrintingSamples(false);
    }
  }

  const generateNewLabel = async () => {
    // Generates a new label
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png", 1.0);

    const labelDimensions = 100;
    var pdf;

    // Creates a new pdf OR fetches the existing pdf and adds a new page
    if (tempPDF) {
      pdf = tempPDF.addPage([labelDimensions, labelDimensions], 'p');
    } else {
      pdf = new jsPDF('p','mm',[labelDimensions,labelDimensions]);
    }

    // Add the new label to the pdf
    pdf.addImage(image, 'PNG', 0, 0, labelDimensions, labelDimensions);

    setTempPDF(pdf);
    checkForMultiplePrints(pdf);
  };

  // Generate new label whenever barcode changes
  useEffect(() => {
    if(barcode) {
      generateNewLabel();
    }
  },[barcode])

  const saveSample = () => {
    setIsPrintingSamples(true);

    authAxiosRequest('/saveSampleDetails', {
      method: 'POST',
      data: sampleData,
      "headers": {
        "content-type": "application/json",
        }
    })		
    .then(response => {
      setNumberOfSaves(numberOfSaves + 1);
      setBarcode(response.data);
    })
    .catch(error => {
      console.log('Error occurred', error);
      setIsPrintingSamples(false);
    })
  }

  return (
    <PageContainer title="Print Label">

      <RadioGroup
        row
        className="hmBodyText"
        aria-labelledby="print-label-options"
        defaultValue="withProductNumber"
        name="print-label-options"
        onChange={(event) => {
          if (showScreen === 'withProductNumber') {
            setSampleData(initialSampleDataWithoutPN);
          } else {
            setSampleData(initialSampleDataWithPN);
            setProductInfoLoaded(false);
          }

          setShowScreen(event.target.value);
        }}
      >
        <FormControlLabel value="withProductNumber" control={<Radio color="default" />} label="With Product number" />
        <FormControlLabel value="withoutProductNumber" control={<Radio color="default" />} label="Without Product number" />
      </RadioGroup>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} mt={5} mb={2}>
        <LabelPreview
          sampleData={sampleData}
          printRef={printRef}
          barcodeValue={barcode}
          showScreen={showScreen}
        />
        
        {showScreen === 'withProductNumber' && (
          <AddSampleFieldsWithPN
            selectedShowroomID={selectedShowroomID}
            sampleData={sampleData}
            setSampleData={setSampleData}
            productInfoLoaded={productInfoLoaded}
            setProductInfoLoaded={setProductInfoLoaded}
            maxTextLengths={maxTextLengths}
          />
        )}
        {showScreen === 'withoutProductNumber' && (
          <AddSampleFieldsWithoutPN
            sampleData={sampleData}
            setSampleData={setSampleData}
            sectionList={sectionList}
            setSectionList={setSectionList}
            departmentList={departmentList}
            setDepartmentList={setDepartmentList}
            maxTextLengths={maxTextLengths}
          />
        )}
      </Stack>
      
      <Stack direction="row" spacing={2} alignItems="end">
        <NumberInput
          label="Number of samples"
          name="numberOfSamples"
          value={numberOfSamples}
          min="1"
          max="10"
          onChange={e => {setNumberOfSamples(e.target.value)}}
          isRequired
          isInvalid={numberOfSamples < 1 || numberOfSamples > 10}
          isDisabled={isPrintingSamples}
          isMarginless
        />
        <PrimaryButton
          onClick={() => saveSample()}
          type="button"
          isDisabled={saveFunctionalityDisabled}
          isLoading={isPrintingSamples}
          iconPosition="start"
          iconElement={<CopyIcon size='small' />}
        >
          Save & print {numberOfSamples} label{numberOfSamples>1 ? 's' : ''}
        </PrimaryButton>
        <SecondaryButton
          onClick={() => resetSampleData()}
          type="button"
          isDisabled={isPrintingSamples}
          iconPosition="start"
          iconElement={<RefreshIcon size='small' />}
        >
          Reset fields
        </SecondaryButton>
      </Stack>

      {numberOfSamples > 10 && (
        <div style={{ margin: '16px 0 -16px' }}>
          <BannerNotification 
            severity="error"
            message={'It is not possible to print more than 10 samples at a time.'}
            closeLabel="Close"
          />
        </div>
      )}
    </PageContainer>
  );
}

export default PrintLabel;
