import React, {useState} from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, RadioGroup, Paper, FormControlLabel, Tooltip, Stack } from '@mui/material';

import { BodyText, HeadingXSmall, HeadingMedium, Modal, PrimaryButton, SecondaryButton, IconButton, BannerNotification } from "@hm-group/fabric-components/brand/hm";
import { BagIcon, ReturnsIcon, TrashIcon, GiftCardIcon } from "@hm-group/fabric-icons";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import LoanReceiptOptions from './LoanReceiptOptions';

export default function SamplesList({ title, samples, loanInfo, contactInfo, selectedShowroomID, refreshLoanData }) {
  const [sampleForDeletion, setSampleForDeletion] = useState();

  const { authAxiosRequest } = useAuthAxiosRequest();

  const sampleStatus = {
    gift: 1,
    lent: 2,
    return: 3,
    delete: 4,
  }

  function ChangeSampleStatus(loanID, newStatus, sampleID) {
    authAxiosRequest("/updateLoanStatus/"+ loanID+"/"+newStatus+"/"+sampleID, {
      method: 'POST',
    })
    .then(response => {
      // TO DO - this behaviour is really slow in FE
      refreshLoanData();
    })
    .catch(error => console.log(error));
  }

  function DeleteSample() {
    ChangeSampleStatus(loanInfo.loanID, sampleStatus.delete, sampleForDeletion);
    // TO DO - this is the logic that existed before. Feels weird to just close the confirmation modal even if an error happens in ChangeSampleStatus
    setSampleForDeletion(); 
  }

  return (
    <>
      {sampleForDeletion && (
        <Modal
          isOpen={sampleForDeletion}
          heading="Remove Sample"
          content="Are you sure you want to delete this sample?"
          closeLabel="Close"
          size="medium"
          onClose={() => setSampleForDeletion()}
          actions={
            <>
              <PrimaryButton onClick={DeleteSample}>Yes</PrimaryButton>
              <SecondaryButton onClick={() => setSampleForDeletion()}>No</SecondaryButton>
            </>
          }
        />
      )} 

      <Stack direction="row" justifyContent="space-between" alignItems="end" paddingBottom={2}>
        <HeadingMedium>{title}</HeadingMedium>
        <LoanReceiptOptions
          samples={samples}
          loanInfo={loanInfo}
          contactInfo={contactInfo}
          selectedShowroomID={selectedShowroomID}
        />
      </Stack>

      {contactInfo.emailAddresses.length === 0 && (
        <BannerNotification 
          severity="warning"
          message={`It is not possible to send a receipt to ${contactInfo.name} since there is no email registered to the contact or their media.`}
          closeLabel="Close"
        />
      )}

      <TableContainer component={Paper} style={{ marginBottom: '16px'}}>
        <Table aria-label={title}>
          <TableHead>
            <TableRow>
              <TableCell><HeadingXSmall>Product Details</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Barcode</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Sample Information</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Section | Department</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall style={{whiteSpace: 'nowrap' }}>In Shop</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Price</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Composition</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Colors</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Channel</HeadingXSmall></TableCell>
              <TableCell align="center"><HeadingXSmall>Sizes</HeadingXSmall></TableCell>
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableHead>
          {samples && samples.length > 0 ? (
            <TableBody>
              {samples.map((sample) => (
                <TableRow
                  key={sample.sampleId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell><BodyText>
                    {sample.productNumber ? `${sample.productNumber} ${sample.productClassification}` : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.barcode || '-'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.information || '-'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.sectionName} | {sample.departmentName}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.productNumber ? sample.inShopWeek : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.productNumber ? `${sample.price} ${sample.currencyCode}` : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.productNumber ? sample.composition : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.productNumber ? sample.colors : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center"><BodyText>
                    {sample.productNumber ? `${sample.channelOffline} ${sample.channelOnline}` : '**'}
                  </BodyText></TableCell>
                  <TableCell align="center" sx={{ maxWidth: 150 }}>
                    <BodyText style={{overflowWrap: 'break-word' , textOverflow: 'ellipsis'}}>
                      {sample.productNumber ? sample.sizes : '**'}
                    </BodyText>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: 260 }}>
                    <RadioGroup
                      row 
                      onChange={(event,value) => ChangeSampleStatus(loanInfo.loanID, value, sample.sampleId)}
                    >
                      <Tooltip title="Lent" placement="top">    
                        <FormControlLabel
                          control={<Radio color="default" checked={sample.loanSample.sampleStatudId === 2}/>}
                          value={sampleStatus.lent}
                          label={<BagIcon />}
                          labelPlacement="top"
                        />
                      </Tooltip>

                      <Tooltip title="Gift" placement="top">
                        <FormControlLabel
                          control={<Radio color="default" checked={sample.loanSample.sampleStatudId === 1}/>}
                          value={sampleStatus.gift}
                          label={<GiftCardIcon />}
                          labelPlacement="top"
                        />
                      </Tooltip>
                
                      <Tooltip title="Return" placement="top">  
                        <FormControlLabel
                          control={<Radio color="default" checked={sample.loanSample.sampleStatudId === 3}/>}
                          value={sampleStatus.return}
                          label={<ReturnsIcon />}
                          labelPlacement="top"
                        />
                      </Tooltip>
                    </RadioGroup>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip disableFocusListener title="Delete" placement="top">
                      {/* TO DO - maybe this logic should be nicer
                      It's not obvious that setSampleForDeletion triggers a confirmation modal
                      How can I name the function so it's obvious that it triggers confirmation modal while also saving the sampleID? */}
                      <IconButton
                        onClick={e => setSampleForDeletion(sample.sampleId)}
                        iconElement={<TrashIcon />}
                      />
                    </Tooltip>   
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <BodyText>No samples.</BodyText>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <BodyText>
        * Varies since multiple colors and composition. Go to PST for more details.<br/>
        ** No info since no Product Number
      </BodyText>
    </>
  )
}
