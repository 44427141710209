import React, {useState, useEffect} from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { Grid, IconButton } from '@material-ui/core';
import { FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Tooltip, Stack } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import { BodyText, HeadingSmall, HeadingXSmall, BannerNotification, NumberInput, IconButton as IconButton1 } from "@hm-group/fabric-components/brand/hm";
import { SearchIcon } from "@hm-group/fabric-icons";

import { Form } from '../../components/useForm';
import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import PageContainer from '../../components/PageContainer';

export default function  ReturnSample({ submenu }) {
  const [barcodeList, setBarcodeList] = React.useState('');
  const [barcodeNoInput, setBarcodeNoInput] = React.useState([]);
  const [scan, setScan] = React.useState(false);
  const [showTable, setshowTable] = React.useState(false);
  const [returnedSampleData, setReturnedSampleData] = useState([]);
  const [showWrongBarcodeNoError, setShowWrongBarcodeNoError] = useState(false);
  const [showBarCodeScanError, setShowBarCodeScanError] = useState(false);

  const { authAxiosRequest } = useAuthAxiosRequest();

  const handleBarCodeScanner = (error, result) => {
    if (result) {
      setBarcodeNoInput(result.text);
      window.navigator.vibrate(100);
      setScan(false);
      setShowBarCodeScanError(false);
    }
    else {
      setShowBarCodeScanError(true);
    }
  }

  var BarCodes;
  
  useEffect(() => {
    setBarcodeList(barcodeList+','+barcodeNoInput);
    console.log('barcodes:', barcodeList);
    BarCodes = {
        "barcodes" : barcodeList
      };
  }, [barcodeNoInput]);
    
  function handleSubmit(event) {
    event.preventDefault();
    setShowWrongBarcodeNoError(false);

    //setBarcodeList(barcodeList+','+barcodeNoInput);
    // var BarCodes = {
    //   "barcodes" : barcodeNoInput
    // };
    if(BarCodes !== undefined && BarCodes !== "") {
      authAxiosRequest('/returnSamples', {
        method: 'POST',
        data: BarCodes,
        "headers": {
          "content-type": "application/json",
          }
      })		
      .then(response => {
        if(response.data.Failure.length > 0){
          console.log("index of::"+response.data.Failure.indexOf(barcodeNoInput));
          setShowWrongBarcodeNoError(true);
          setShowBarCodeScanError(false);
        } 
        if(response.data.Success.length > 0) {
          setReturnedSampleData(response.data.Success);
          //setShowWrongBarcodeNoError(false);
          setShowBarCodeScanError(false);
          setshowTable(true);
        }  
      })
      .catch(error => {
        setShowWrongBarcodeNoError(true);
        setshowTable(false);
        setShowBarCodeScanError(false);
      })
    }	
    else {
      setBarcodeList(barcodeList+','+barcodeNoInput);
      console.log('barcodes:', barcodeList);
      BarCodes = {
          "barcodes" : barcodeList
        };
      handleSubmit(event);  
    }
  }

  const handleCloseStatusMessage = () => {
    setShowWrongBarcodeNoError(false);
    setShowBarCodeScanError(false);
  }
  
  return (
    <PageContainer title="Return sample" submenu={submenu}>    
    <Box sx={{ flexGrow: 2 }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <div>
                <FormControl>
                  <div style={{ justifyContent: 'left' , marginBottom: '50px', width: '50%'}}>  
                    <BodyText>Enter the 10 digit barcode number or scan the barcode to return the sample.</BodyText>

                    <Stack direction="row" marginTop={2} alignItems='end'>
                    <NumberInput
                      label='Barcode'
                      placeholder='10-digit barcode'
                      name="BarcodeNo"
                      isMarginless
                      value={barcodeNoInput ? barcodeNoInput : ''}
                      onChange={e => setBarcodeNoInput(e.target.value)}
                    />
                    <IconButton1
                      iconElement={<SearchIcon />}
                      onClick={e => handleSubmit(e)}
                      variant='primary'
                      borderRadius='normal'
                    />
                    </Stack>
                    
                    <Tooltip title="Click here for Scanning barcode" placement="right-end">
                          <IconButton onKeyDown={handleSubmit} onClick={(e) =>{
                              setScan(true);
                              }}>
                              <QrCodeScannerIcon/>
                          </IconButton>
                    </Tooltip>  
                  </div>       
                </FormControl> 
   
                  {
                     scan ? <>
                      <BarcodeScannerComponent
                          width={500}
                          height={500}
                          onUpdate={handleBarCodeScanner}/>
                        <p>{barcodeNoInput}</p>
                      </>  : null
                  } 
                  {showWrongBarcodeNoError && (
                     <BannerNotification 
                     severity="error"
                     message="Invalid or incorrect Barcode. Check if you have entered the correct Barcode numbers."
                     closeLabel="Close"
                   />
                  )}

                   {showBarCodeScanError  && (
                      <BannerNotification 
                        severity="error"
                        message="Barcode Not Found, Please rescan."
                        closeLabel="Close"
                      />
                   )}
             </div>
             <div>
               {
                 showTable ?
                 <>
                 <HeadingSmall>Returned Samples</HeadingSmall>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left"><HeadingXSmall>Barcode&nbsp;</HeadingXSmall></TableCell>
                          <TableCell align="left"><HeadingXSmall>Sample Information&nbsp;</HeadingXSmall> </TableCell>
                          <TableCell align="left"><HeadingXSmall>Product&nbsp;</HeadingXSmall></TableCell>
                          <TableCell align="left"><HeadingXSmall>Loan Nbr&nbsp;</HeadingXSmall></TableCell>
                          <TableCell align="left"><HeadingXSmall>Contact&nbsp;</HeadingXSmall></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {returnedSampleData.map((row) => (
                            row ?
                            <TableRow
                              key={row}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell align="left"><BodyText>{row?row[0][0]:''}</BodyText></TableCell>
                              <TableCell align="left"><BodyText>{row?row[0][1]:''}</BodyText></TableCell>
                              <TableCell align="left"><BodyText>{(row?row[0][2]:'')+' '+(row[0][3]?row[0][3]:row[0][3])}</BodyText></TableCell>
                              <TableCell align="left"><BodyText>{row?row[0][4]:''}</BodyText></TableCell>
                              <TableCell align="left"><BodyText>{(row?row[0][5]:'')+' '+(row[0][6]?row[0][6]:'')}</BodyText></TableCell>
                            </TableRow> : null
                          ))}
                        </TableBody>
                      </Table> 
                  </TableContainer>  
                </> : null
                }
              </div>
            </Grid>  
          </Grid>
        </Form> 
      </Box>  
    </PageContainer>
  )
};
