import React, {useState, useEffect } from 'react';

import { Stack, Grid } from "@mui/material";
import { Select, DropDownItem, BannerNotification } from "@hm-group/fabric-components/brand/hm";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import TextareaWithLimitedLength from './TextareaWithLimitedLength';

const AddSampleFieldsWithoutPN = ({ sampleData, setSampleData, sectionList, setSectionList, departmentList, setDepartmentList, maxTextLengths }) => {
  const [loadError, setLoadError] = useState();

  const { authAxiosRequest } = useAuthAxiosRequest();

  useEffect( () => {
    const loadSections = async() => {
      const response = await authAxiosRequest("/getSections");
      const hmSections = response.data.filter(section => section.CorporateBrandId === 0).map(section => ({id: section.SectionId, label: section.SectionName}));
      setSectionList(hmSections);
    };

    loadSections();
  },[])

  const loadDepartments = (sectionID, sectionName) => {
    authAxiosRequest("/getDepartments/"+ sectionID, { method: 'POST' })
    .then(response => {
      setSampleData({
        ...sampleData,
        sectionName: sectionName,
        departmentName: '',
      });

      setDepartmentList(response.data.map(department => department.DepartmentName));

      if(loadError) {
        setLoadError();
      }
    })
    .catch(error => {
      setDepartmentList([]);
      setSampleData({
        ...sampleData,
        departmentName: '',
      });
      setLoadError("We couldn't find any departments that belong to this section. Please choose a different section.");
    })	
  }

  return (
    <Grid container columnSpacing={{xs: 0, md: 3}} rowSpacing={{xs: 2, lg: 0}}>
      <Grid item xs={12} lg={6}>
        <Stack direction="column" spacing={2}>
          <Select
            label="Section"
            ariaLabel="Section"
            value={sampleData.sectionName}
            name="section"
            placeholder="Select..."
            onSelected={e => {
              sectionList.forEach(section => {
                if (section.label === e.label) {
                  loadDepartments(section.id, section.label);
                }
              })
            }}
            isRequired
          >
            {sectionList.map(section => <DropDownItem label={section.label} />)}
          </Select>

          {loadError && (
            <BannerNotification 
              severity="error"
              message={loadError}
              closeLabel="Close"
            />
          )}

          <Select
            label="Department"
            ariaLabel="Department"
            value={sampleData.departmentName}
            name="department"
            placeholder="Select..."
            onSelected={e => setSampleData({...sampleData, departmentName: e.label})}
            isRequired
            isDisabled={departmentList.length === 0}
          >
            {departmentList.map(department => <DropDownItem label={department} />)}
          </Select>

          <TextareaWithLimitedLength
            value={sampleData.information}
            name='information'
            label='Sample information'
            sampleData={sampleData}
            setSampleData={setSampleData}
            maxLength={maxTextLengths.information}
            updateState={(event) => setSampleData({...sampleData, information: event.target.value})}
          />  
        </Stack>
      </Grid>
    </Grid>
  );
}

export default AddSampleFieldsWithoutPN;