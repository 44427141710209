import { LogLevel } from "@azure/msal-browser";

// Configuration object to be passed to MSAL instance on creation.
export const msalConfig = {
    auth: {
        // Azure portal > App registrations > showroomdev
        clientId: "ae8a69d9-e183-4b93-b267-56e8d98d43cc",
        // Tenant ID
        authority:"https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
        redirectUri: `${process.env.REACT_APP_PROTOCOL}://${window.location.host}/loans`,
    },
    cache: {
        cacheLocation: "localStorage",
    },
    // TODO: Leave as comment until we implemenent a solution to show this on non-prod environments only
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return;
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message);
    //                     return;
    //                 case LogLevel.Info:
    //                     console.info(message);
    //                     return;
    //                 case LogLevel.Verbose:
    //                     console.debug(message);
    //                     return;
    //                 case LogLevel.Warning:
    //                     console.warn(message);
    //                     return;
    //                 default:
    //                     return;
    //             }
    //         },
    //     },
    //     allowNativeBroker: false,
    // },
};

export const msalScopes = {
    scopes: ["api://ae8a69d9-e183-4b93-b267-56e8d98d43cc/User.Read"],
}
