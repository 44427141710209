import {React,useEffect,useState} from 'react';

import { AppBar, Toolbar } from '@material-ui/core';
import { Container, Stack, Select, MenuItem } from '@mui/material';
import { SecondaryLink, NavigationLarge } from "@hm-group/fabric-components/brand/hm";
import { NewWindowIcon, SettingsIcon } from "@hm-group/fabric-icons";

export default function Header({ assignedShowroom, setAssignedShowroom, showroomList }) {
  const [loanMenuSelected, setLoanMenuSelected] = useState(false);
  const [contactMenuSelected, setContactMenuSelected] = useState(false);
  const [sampleMenuSelected, setSampleMenuSelected] = useState(false);

  useEffect(() => {
     const url = window.location.pathname;
      if(url === '/contacts'){
        setContactMenuSelected(true);
        setLoanMenuSelected(false);
        setSampleMenuSelected(false);
      } else if(url === '/loans') {
        setLoanMenuSelected(true);
        setContactMenuSelected(false);
        setSampleMenuSelected(false);
      } else if(url === '/print-label') {
        setSampleMenuSelected(true);
        setContactMenuSelected(false);
        setLoanMenuSelected(false);
      }
	}, []);

  return (
    <AppBar position="static" color="transparent">
      <Toolbar disableGutters>
        <Container maxWidth="xl">
          <Stack direction='row' spacing={{ xs: 2, sm: 3, md: 5 }} justifyContent='space-between' alignItems='center'>
            <div>
              <img src= {require('../images/HMGroupLoGo.jpg')} alt='hmgrouplogo' style={{display: 'block'}}/>
            </div>
                    
            <Stack alignItems='center' direction='row' spacing={{ xs: 2, sm: 3, md: 5, lg: 8 }}>
              <NavigationLarge 
                href="/loans"
                // inline={loanMenuSelected}
                // selected={loanMenuSelected}
                // onClick={(event)=>{
                //     setLoanMenuSelected(true);
                //     setContactMenuSelected(false);
                //     setSampleMenuSelected(false);
                // }}
              >
                Loans
              </NavigationLarge>
              
              <NavigationLarge 
                href="/print-label"
                // inline={sampleMenuSelected}
                // selected={sampleMenuSelected}
                // onClick={(event)=>{
                //     setContactMenuSelected(false);
                //     setLoanMenuSelected(false);
                //     setSampleMenuSelected(true);
                // }}
              >
                Samples
              </NavigationLarge>
              
              <NavigationLarge 
                href="/contacts"
                // inline={contactMenuSelected}
                // selected={contactMenuSelected}
                // onClick={(event)=>{
                //   setSampleMenuSelected(false);
                //   setLoanMenuSelected(false);
                //   setContactMenuSelected(true);
                // }}
              >
                Contacts
              </NavigationLarge>
              
              <SecondaryLink
                iconPosition='end'
                iconElement={<NewWindowIcon size='small'/>}
                href="https://productsearchtool.hmgroup.com/"
                target="_blank"
              >
                Search product
              </SecondaryLink>
            </Stack>

            <Stack alignItems='center' direction='row' spacing={{ xs: 1, sm: 1, md: 2 }}>
              {assignedShowroom && (
                <Select
                  id="showroom-select"
                  value={assignedShowroom}
                  onChange={e => {
                    setAssignedShowroom(e.target.value);
                    localStorage.setItem('selectedShowroom', e.target.value);
                  }}
                >
                  {showroomList.map(showroom => <MenuItem key={showroom.value} value={showroom.value} name={showroom.label}>{showroom.label}</MenuItem>)}
                </Select>
              )}

              <NavigationLarge href="/admin/users">
                <SettingsIcon />
              </NavigationLarge>
            </Stack>
          </Stack>
        </Container>
      </Toolbar> 
    </AppBar>
  ); 
}
