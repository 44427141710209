import React from 'react'

import { HeadingLarge, Preamble } from "@hm-group/fabric-components/brand/hm";

export default function AccessRestrictedPage({ title, text, children }) {
    return (
        <div style={{ width: '100%', height: '100vh', padding: '20vh 30%', textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <HeadingLarge>{title || "Access Restricted"}</HeadingLarge>
            <Preamble dangerouslySetInnerHTML={{__html: text || "You don't have permission to view this page."}} />
            {children}
        </div>
    )
}
