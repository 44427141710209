import {React,useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

import { makeStyles, CssBaseline, createTheme, ThemeProvider } from '@material-ui/core';
import { PlusIcon, ViewListIcon, SearchIcon, ReturnsIcon, ErrorIcon, AccountDefaultIcon } from "@hm-group/fabric-icons";

import { useAuthAxiosRequest } from './hooks/useAuthAxiosRequest';

import './App.css';

import { ErrorPage } from './pages/ErrorPage';
import SearchLoans from './pages/Loans/SearchLoans';
import OverdueLoans from './pages/Loans/OverdueLoans';
import AddLoan from './pages/Loans/AddLoan';
import ReturnSample from './pages/Loans/ReturnSample';
import LoanDetails from "./pages/Loans/LoanDetails";
import PrintLabel from './pages/Samples/PrintLabel';
import Contacts from './pages/Contacts/Contacts';
import AddContact from './pages/Contacts/AddContact';
import ContactInfo from './pages/Contacts/ContactInfo';
import Medias from './pages/Medias/Medias';
import AddMedia from './pages/Medias/AddMedia';
import MediaInfo from './pages/Medias/MediaInfo';
import ShowroomUsers from './pages/Admin/Users/ShowroomUsers';
import AdminShowrooms from './pages/Admin/Showrooms/AdminShowrooms';
import UserShowrooms from './pages/Admin/Showrooms/UserShowrooms';
import EditShowroom from './pages/Admin/Showrooms/EditShowroom';
import Header from './components/Header';

const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: '#3c44b126'
    },
    secondary: {
      main: "#f83245",
      light: '#f8324526'
    },
    background: {
      default: "#FFF"
    },
    font: {
      default: "HM Sans Regular"
    }
  },
  overrides:{
    MuiAppBar:{
      root:{
        transform:'translateZ(0)'
      }  
    },
    MuiGrid:{
      container:{
        flexWrap:'nowrap'
      }
    }
  },
  props:{
    MuiIconButton:{
      disableRipple:true
    },
   }
})

const useStyles = makeStyles({
  appMain: {
    width: '100%'
  }
})

export default function App() {
  const classes = useStyles();
  const [showroomList, setShowroomList] = useState([]);
  const [assignedShowroom, setAssignedShowroom] = useState();
  const [selectedShowroomLabel, setSelectedShowroomLabel] = useState('');
  const userId = localStorage.getItem('user_id');
  const {authAxiosRequest} = useAuthAxiosRequest();
  
  useEffect(() => {
    const loadShowrooms = async() => {
      const response = await authAxiosRequest("/getAllShowrooms/"+userId);

      if(localStorage.getItem('selectedShowroom')) {
        setAssignedShowroom(localStorage.getItem('selectedShowroom'));
      } else {
        setAssignedShowroom(response.data.find(showroom => showroom.default_showroom === 1)?.showroom_id);
      }

      var resultShowrooms = response.data.map(eachShowroom => ({ value:eachShowroom.showroom_id, label:eachShowroom.showroom_name}));
      setShowroomList(resultShowrooms);
    };

    loadShowrooms();
  },[]);

  useEffect(() => {
    setSelectedShowroomLabel(showroomList.find(showroom => showroom.value === parseInt(assignedShowroom))?.label);
  },[assignedShowroom, showroomList]);

  const contactsSubmenu = [
    {
      url: '/contacts',
      label: 'Contacts',
      icon: <ViewListIcon size="small" />,
    },
    {
      url: '/medias',
      label: 'Medias',
      icon: <ViewListIcon size="small" />,
    },
    {
      url: '/contacts/add',
      label: 'Add Contact',
      icon: <PlusIcon size="small" />,
    },
    {
      url: '/medias/add',
      label: 'Add Media',
      icon: <PlusIcon size="small" />,
    }
  ]

  const loansSubmenu = [
    {
      url: '/loans',
      label: 'Search loans',
      icon: <SearchIcon size="small" />,
    },
    {
      url: '/overdue-loans',
      label: 'Overdue loans',
      icon: <ErrorIcon size="small" />,
    },
    {
      url: '/add-loan',
      label: 'Add Loan',
      icon: <PlusIcon size="small" />,
    },
    {
      url: '/return-sample',
      label: 'Return sample',
      icon: <ReturnsIcon size="small" />,
    }
  ]

  const loggedInUserIsAdmin = parseInt(localStorage.getItem('user_role')) === 2;

  const adminSubmenu = [
    {
      url: '/admin/users',
      label: 'Showroom users',
      icon: <AccountDefaultIcon size="small" />,
    },
    {
      url: '/admin/showrooms',
      label: 'Showrooms',
      icon: <ViewListIcon size="small" />,
    }
  ]

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.appMain}>
        <Router>
          {/* TODO: Move Header to PageContainer */}
          <Header
            assignedShowroom={assignedShowroom}
            setAssignedShowroom={setAssignedShowroom}
            showroomList={showroomList}
          />
          {assignedShowroom && showroomList.length > 0 && (
            <Routes>
              <Route path="/" element={ <Navigate to='/loans' /> } />
              {/* Temporary fix, remove this redirect later */}
              <Route path="/loan/default/1" element={ <Navigate to='/loans' /> } />
              <Route path = '/contacts' element={<Contacts selectedShowroomID={assignedShowroom} submenu={contactsSubmenu} />}/>
              <Route path = '/contacts/add' element={<AddContact selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
              <Route path = '/contact/:contactID' element={<ContactInfo selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
              <Route path = '/medias' element={<Medias selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
              <Route path = '/medias/add' element={<AddMedia selectedShowroomID={assignedShowroom} submenu={contactsSubmenu} />}/>
              <Route path = '/media/:mediaID' element={<MediaInfo selectedShowroomID={assignedShowroom} submenu={contactsSubmenu}/>}/>
              <Route path = '/loans' element={<SearchLoans selectedShowroomID={assignedShowroom} submenu={loansSubmenu} />}/>
              <Route path = '/overdue-loans' element={<OverdueLoans selectedShowroomID={assignedShowroom} submenu={loansSubmenu} />}/>
              <Route path = '/add-loan' element={<AddLoan selectedShowroomID={assignedShowroom} submenu={loansSubmenu}/>}/>
              <Route path = '/return-sample' element={<ReturnSample selectedShowroomID={assignedShowroom} submenu={loansSubmenu}/>}/>
              <Route path = '/loan/:loanID' element={<LoanDetails selectedShowroomID={assignedShowroom} />}/>
              <Route path = '/admin/users' element={<ShowroomUsers
                selectedShowroom={{name: selectedShowroomLabel, id: assignedShowroom}} 
                submenu={adminSubmenu}
                loggedInUserIsAdmin={loggedInUserIsAdmin}
              />}/>
              <Route path = '/admin/showrooms' element={loggedInUserIsAdmin ? 
                <AdminShowrooms submenu={adminSubmenu} /> : 
                <UserShowrooms submenu={adminSubmenu} userShowrooms={showroomList} />}
              />
              <Route path = '/admin/showroom/:showroomID' element={
                <EditShowroom
                  submenu={adminSubmenu}
                  userShowrooms={showroomList}
                  loggedInUserIsAdmin={loggedInUserIsAdmin}
                />
              }/>
              <Route path = '/print-label' element={<PrintLabel selectedShowroomID={assignedShowroom} />} />
              <Route path = "*" element={<ErrorPage/>} />
            </Routes>
          )}
        </Router>
      </div>
      <CssBaseline />
    </ThemeProvider>
  ); 
}

