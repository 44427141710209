import React, {useState, useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Stack, Grid } from "@mui/material";
import { PrimaryButton, TextInput, NumberInput, TextArea, HeadingSmall, HeadingXSmall, BodyText } from "@hm-group/fabric-components/brand/hm";

import { useAuthAxiosRequest } from '../../../hooks/useAuthAxiosRequest';

import PageContainer from '../../../components/PageContainer';
import AccessRestrictedPage from '../../AccessRestrictedPage';

export default function EditShowroom({ submenu, userShowrooms, loggedInUserIsAdmin }) {
  const [accessRestricted, setAccessRestricted] = useState(true);
  const [showroomInfo, setShowroomInfo] = useState({});

  const {showroomID} = useParams();
  const navigate = useNavigate();
  const { authAxiosRequest } = useAuthAxiosRequest();

  const maxTextAreaLength = 120;
  const maxAdditionalInfoLength = 300;

  // Check if user has access to this page
  useEffect(() => {
    if (loggedInUserIsAdmin) {
      setAccessRestricted(false);
    } else {
      const userHasAccess = userShowrooms.find(showroom => showroom.value === parseInt(showroomID));
      if (userHasAccess) {
        setAccessRestricted(false);
      }
    }
  },[])

  // Get Showroom info on load
  useEffect(() => {
    const getShowroomInfo = () => {
      authAxiosRequest('/getAllShowrooms', { params: { 'showroomId': showroomID } })
      .then(response => {
        setShowroomInfo(response.data.content[0]);
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getShowroomInfo();
  },[showroomID])

  const saveChanges = () => {
    authAxiosRequest('/updateShowroom', {
      method: 'PUT',
      data: showroomInfo,
    })
    .then(response => {
      navigate('/admin/showrooms', { state: `${showroomInfo.showroomName} showroom was successfully updated.`});
    })
    .catch(error => {
      console.log(error);
    })
  }

  if (accessRestricted) {
    return <AccessRestrictedPage />;
  } else {
    return (
      <PageContainer
        title={`${showroomInfo.showroomName} showroom`}
        submenu={submenu}
      >
        <Grid container rowSpacing={2} columnSpacing={6}>
          <Grid item xs={12} md={5} lg={4}>
            <Stack direction="column" spacing={2}>
              <HeadingSmall>SAMPLES INFO</HeadingSmall>

              <NumberInput
                isRequired
                label="Loan days"
                name="loanDays"
                onChange={e => setShowroomInfo({...showroomInfo, loanPeriodDays: e.target.value})}
                value={showroomInfo.loanPeriodDays || ''}
                helperText="Number of days in which the contact must return the sample to the showroom"
              />

              <TextArea
                value={showroomInfo.lentSamplesInstructions || ''}
                name="lentSamplesInstructions"
                label="Lent samples instructions"
                onChange={e => setShowroomInfo({...showroomInfo, lentSamplesInstructions: e.target.value})}
                maxLength={maxTextAreaLength}
                isInvalid={showroomInfo.lentSamplesInstructions && showroomInfo.lentSamplesInstructions.length > maxTextAreaLength}
                helperText={`The text may not be longer than ${maxTextAreaLength} characters. It is currently ${showroomInfo.lentSamplesInstructions ? showroomInfo.lentSamplesInstructions.length : 0} characters long.`}
              />

              <TextArea
                value={showroomInfo.giftSamplesInstructions || ''}
                name="giftSamplesInstructions"
                label="Gift samples instructions"
                onChange={e => setShowroomInfo({...showroomInfo, giftSamplesInstructions: e.target.value})}
                maxLength={maxTextAreaLength}
                isInvalid={showroomInfo.giftSamplesInstructions && showroomInfo.giftSamplesInstructions.length > maxTextAreaLength}
                helperText={`The text may not be longer than ${maxTextAreaLength} characters. It is currently ${showroomInfo.giftSamplesInstructions ? showroomInfo.giftSamplesInstructions.length : 0} characters long.`}
              />
            </Stack>
          </Grid>
          
          <Grid item xs={12} md={5} lg={4}>
            <Stack direction="column" spacing={2}>
              <HeadingSmall>CONTACT INFO</HeadingSmall>

              <BodyText>Contact info of the showroom or showroom responsible.</BodyText>

              <TextInput
                label="Phone number"
                name="phoneNumber"
                value={showroomInfo.phoneNumber || ''}
                placeholder='e.g. +1234567890'
                inputMode="tel"
                onChange={e => setShowroomInfo({...showroomInfo, phoneNumber: e.target.value})}
              />

              <TextInput
                isRequired
                label="Email"
                name="email"
                value={showroomInfo.email || ''}
                placeholder="e.g. example@email.com"
                inputMode="email"
                onChange={e => setShowroomInfo({...showroomInfo, email: e.target.value})}
              />

              <TextArea
                isRequired
                value={showroomInfo.address || ''}
                name="address"
                label="Address"
                onChange={e => setShowroomInfo({...showroomInfo, address: e.target.value})}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={5} lg={4}>
            <Stack direction="column" spacing={2}>
              <HeadingSmall>ADDITIONAL INFO</HeadingSmall>
              
              <BodyText>If there is any other information that needs to be shared with the contact, for legal or other purposes, please add it here.</BodyText>
              
              <TextArea
                value={showroomInfo.additionalInfo || ''}
                name="additionalInfo"
                label="Additional info"
                onChange={e => setShowroomInfo({...showroomInfo, additionalInfo: e.target.value})}
                maxLength={maxAdditionalInfoLength}
                isInvalid={showroomInfo.additionalInfo && showroomInfo.additionalInfo.length > maxAdditionalInfoLength}
                helperText={`The text may not be longer than ${maxAdditionalInfoLength} characters. It is currently ${showroomInfo.additionalInfo ? showroomInfo.additionalInfo.length : 0} characters long.`}
              />
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" paddingTop={3} paddingBottom={5}>
          <PrimaryButton
            onClick={() => saveChanges()}
            type="button"
            isDisabled={!showroomInfo.loanPeriodDays || !showroomInfo.email || !showroomInfo.address}
          >
            Save changes
          </PrimaryButton>
        </Stack>

        <div>
          <HeadingXSmall>HELPER: Former Loan receipt footer text</HeadingXSmall>

          <Stack direction="column" padding={2} marginY={1} style={{ backgroundColor: 'rgb(243, 237, 232)' }}>
            <BodyText isMarginless dangerouslySetInnerHTML={{__html: showroomInfo.loanFooterText1}}/>
            <BodyText isMarginless dangerouslySetInnerHTML={{__html: showroomInfo.loanFooterText2}}/>
            <BodyText isMarginless dangerouslySetInnerHTML={{__html: showroomInfo.loanFooterText3}}/>
            <BodyText isMarginless dangerouslySetInnerHTML={{__html: showroomInfo.loanFooterText4}}/>
          </Stack>

          <BodyText>Note: Previously it was possible to add styling to text. This is not possible anymore. Please use plain text in the above fields.</BodyText>
        </div>
      </PageContainer>
    );
  }
}
