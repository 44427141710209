import React, {useState, useEffect} from 'react';
import validator from 'validator';
import { useNavigate } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Stack, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { HeadingSmall, BodyText, FormLabel, BannerNotification, Modal, PrimaryButton, ToggleSwitch, TextInput } from "@hm-group/fabric-components/brand/hm";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import PageContainer from '../../components/PageContainer';

export default function AddMedia({ selectedShowroomID, submenu }){
    const [countryList, setCountryList] = useState([]);
    const [countryDelvAddr, setCountryDelvAddr] = useState('');
	const [countryMailingAddr, setCountryMailingAddr] = useState('');
	const [mediaLabelList, setMediaLabelList] = useState([]);
	const [mediaLabel, setMediaLabel] = useState('');
	const [mediaName, setMediaName] = useState('');
	const [enableSave, setEnableSave] = useState(false);
	const [showErrorBar, setShowErrorBar] = React.useState(false);
	const [showPopUp, setShowPopUp] = useState(false);
	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [website, setWebsite] = useState('');
	const [additionalInformation, setAdditionalInformation] = useState('');
	const [deliveryAddress, setDeliveryAddress] = useState('');
	const [deliveryAddress1, setDeliveryAddress1] = useState('');
	const [deliveryAddress2, setDeliveryAddress2] = useState('');
	const [deliveryAddress3, setDeliveryAddress3] = useState('');
	const [mailingAddress, setMailingAddress] = useState('');
	const [mailingAddress1, setMailingAddress1] = useState('');
	const [mailingAddress2, setMailingAddress2] = useState('');
	const [mailingAddress3, setMailingAddress3] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [error, setError] = useState();
	const [emailError, setEmailError] = useState();

	const navigate = useNavigate();
	const { authAxiosRequest } = useAuthAxiosRequest();
		
	const handleError = ({ isValidNumber }) => {
		if (!isValidNumber) {
			setError('Invalid Phone Number');
		} else {
			setError(undefined);
		}
	}

	const validateEmail = (input) => {
		var email = input;
	  
		if (validator.isEmail(email)) {
		  setEmailError(undefined);
		} else {
		  setEmailError('This email is not valid. Please enter a valid email (example@email.com)');
		}
	  }

	useEffect( () => {
		const loadCountries = async() => {
			const response = await authAxiosRequest("/countries");
			var resultCountry = response.data.map(eachCountry => ({ id:eachCountry.GeographicalCountryId, label:eachCountry.CountryOfDeliveryName}));
			setCountryList(resultCountry);
		};
		loadCountries();
	},[])

	useEffect( () => {
		const loadMediaLabels = async() => {
			const response = await authAxiosRequest("/getMediaLabels");
			var resultMediaLabels = response.data.map(eachMediaLabel => ({ value:eachMediaLabel.mediaLabelId, label:eachMediaLabel.mediaLabelName}));
			setMediaLabelList(resultMediaLabels);
		};
		loadMediaLabels();
	},[])

    const saveMedia = ()   => {
		var mediaDetails = {
			"mediaName": mediaName,
			"mediaLabel": mediaLabel,
			"email" : email,
			"phone" : phoneNumber,
			"additionalInfo":additionalInformation,
			"website":website,
			"deliveryAddressLine1":deliveryAddress,
			"deliveryAddressLine2":deliveryAddress1,
			"deliveryAddressLine3":deliveryAddress2,
            "deliveryAddressLine4":deliveryAddress3,
			"countryCodeDelivery":countryDelvAddr,
			"mailingAddressLine1":mailingAddress,
			"mailingAddressLine2":mailingAddress1,
			"mailingAddressLine3":mailingAddress2,
			"mailingAddressLine4":mailingAddress3,
			"countryCodeMailing":countryMailingAddr,
			"showroomId":selectedShowroomID
			}

		authAxiosRequest('/saveMedia', {
			method: 'POST',
			data: mediaDetails,
			"headers": {
				"content-type": "application/json",
				}
		})		
		.then(response => {
			navigate('/contacts/', { state: `The Media <b>${mediaName}</b> was successfully added!`});
		})
		.catch(error => {
			setShowErrorBar(true);	
		})
    } 

	useEffect(() => {
		if(mediaName && mediaLabel){
			setEnableSave(true);
		} else {
			setEnableSave(false);
		}
	  }, [mediaName,mediaLabel]);
	  
	const  onSelectingSameAsDeliveryAddress= (event) => {
		if (!isChecked) {
			setMailingAddress(deliveryAddress);
			setMailingAddress1(deliveryAddress1);
			setMailingAddress2(deliveryAddress2);
			setMailingAddress3(deliveryAddress3);
			setCountryMailingAddr(countryDelvAddr);
		}

		setIsChecked(!isChecked);
    } 

	const onClickOfOk = e   => {
		setShowErrorBar(false);
		setShowPopUp(false);
	}
	
  return (
    <PageContainer title="Add Media" submenu={submenu}>
		<BannerNotification 
            severity="info"
            message="Media information must be general information from the Media, e.g Phone number to Switchboard, E-mail = info@media.com etc. 
			If it’s individual information, it must be registered on the specific Contact."
            shouldHideCloseButton
        />

		{showErrorBar && (
			<Modal
				isOpen={showErrorBar}
				heading="ERROR"
				content="Save Media Failed!"
				closeLabel="Close"
				size="medium"
				onClose={() => setShowErrorBar(!showErrorBar)}
				actions={<PrimaryButton onClick={() => onClickOfOk()}>Ok</PrimaryButton>}
			/>
		)}

		{showPopUp && (
			<Modal
				isOpen={showPopUp}
				heading="Changes Saved"
				content={<BodyText dangerouslySetInnerHTML={{__html: `The media ${mediaName} already exist in the system.<br/>
					Please find the registered Media under Contact/Media Info.<br/>
					<b>If this is a new media</b>: Please add any unique data for the media name to be able to save it as a new media.`}}
				/>}
				closeLabel="Close"
				size="medium"
				onClose={() => setShowPopUp(!showPopUp)}
				actions={<PrimaryButton onClick={() => onClickOfOk()}>Ok</PrimaryButton>}
			/>
		)}

		<Grid container spacing={6}>
			<Grid item xs={4}>
				<Stack direction="column" spacing={2}>
					<HeadingSmall>Contact information</HeadingSmall>

					<TextInput
						label="Media Name"
						name="mediaName"
						isRequired
						value={mediaName}
						onChange={e => setMediaName(e.target.value)}
					/>

					<FormControl required>
						<InputLabel id="mediaLabel-label">Media Label</InputLabel>
						<Select
							id="mediaLabel"
							label="Media Label"
							value={mediaLabel ? mediaLabel : ''}
							onChange={e => setMediaLabel(e.target.value)}
						>
							{mediaLabelList.map(mediaLabel => <MenuItem value={mediaLabel.value}>{mediaLabel.label}</MenuItem>)}
						</Select>
					</FormControl>

					<TextInput
						label="Email"
						name="email"
						value={email}
						placeholder="example@email.com"
						inputMode="email"
						onChange={e => {
							setEmail(e.target.value);
							if (e.target.value.length > 0) {
								validateEmail(e.target.value);
							} else {
								setEmailError(undefined);
							}
						}}
						isInvalid={emailError}
						helperText={emailError}
					/>

					<TextInput
						label="Phone Number"
						name="phoneNumber"
						value={phoneNumber}
						placeholder='e.g. +1234567890'
						inputMode="tel"
						onChange={e => setPhoneNumber(e.target.value)}
						helperText="Please enter a valid phone number. It should start with a '+' followed by country code and contain numbers only."
						// onErrorCallBack={handleError}
						// error={error}
					/>

					<TextInput
						label="Website"
						name="website"
						value={website}
						inputMode="url"
						onChange={e => setWebsite(e.target.value)}
					/>
				</Stack>
			</Grid>

			<Grid item xs={4}>
				<Stack direction="column" spacing={2}>
					<HeadingSmall>Additional information</HeadingSmall>

					<FormLabel>Additional information</FormLabel>
					<TextField
						style={{background: "white", marginTop: '4px'}}
						name="additionalInformation"
						multiline
						rows={5} cols={15}
						value={additionalInformation}
						onChange={(event, value) => setAdditionalInformation(event.target.value)}
					/>

					<BodyText>
						In Additional Info you are only allowed to write relevant facts from an objective point of view.<br/>
						Sensitive information or personal reflections are not allowed. 
					</BodyText>
				</Stack>
			</Grid>
		</Grid>

		<Grid container spacing={6} style={{ paddingTop: '24px'}}>
			<Grid item xs={4}>
				<Stack direction="column" spacing={2}>
					<HeadingSmall>Delivery information</HeadingSmall>

					<TextInput
						label="Delivery Address"
						name="deliveryAddress"
						value={deliveryAddress}
						onChange={e => setDeliveryAddress(e.target.value)}
					/>

					<TextInput
						label="Apartment,suite,etc."
						name="deliveryAddress1"
						value={deliveryAddress1}
						onChange={e => setDeliveryAddress1(e.target.value)}
					/>

					<TextInput
						label="Zip code"
						name="deliveryAddress2"
						value={deliveryAddress2}
						onChange={e => setDeliveryAddress2(e.target.value)}
					/>

					<TextInput
						label="City"
						name="deliveryAddress3"
						value={deliveryAddress3}
						onChange={e => setDeliveryAddress3(e.target.value)}
					/>

					<FormControl>
						<InputLabel id="countryDelvAddr-label">Country</InputLabel>
						<Select
							id="countryDelvAddr"
							label="Country"
							value={countryDelvAddr ? countryDelvAddr : ''}
							onChange={e => setCountryDelvAddr(e.target.value)}
						>
							{countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
						</Select>
					</FormControl>
				</Stack>
			</Grid>
			<Grid item xs={4}>
				<Stack direction="column" spacing={2}>
					<HeadingSmall>Mailing information</HeadingSmall>

					<ToggleSwitch
						id="toggleswitch"
						onChange={() => onSelectingSameAsDeliveryAddress()}
						label="Use same as delivery address"
						isChecked={isChecked}
					/>

					<TextInput
						label="Mailing Address"
						name="mailingAddress"
						value={mailingAddress}
						onChange={e => setMailingAddress(e.target.value)}
					/>

					<TextInput
						label="Apartment,suite,etc."
						name="mailingAddress1"
						value={mailingAddress1}
						onChange={e => setMailingAddress1(e.target.value)}
					/>

					<TextInput
						label="Zip code'"
						name="mailingAddress2"
						value={mailingAddress2}
						onChange={e => setMailingAddress2(e.target.value)}
					/>

					<TextInput
						label="City"
						name="mailingAddress3"
						value={mailingAddress3}
						onChange={e => setMailingAddress3(e.target.value)}
					/>

					<FormControl>
						<InputLabel id="countryMailingAddr-label">Country</InputLabel>
						<Select
							id="countryMailingAddr"
							label="Country"
							value={countryMailingAddr ? countryMailingAddr : ''}
							onChange={e => setCountryMailingAddr(e.target.value)}
						>
							{countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
						</Select>
					</FormControl>
				</Stack>
			</Grid>
		</Grid>

		<Stack direction="row" spacing={2} paddingTop="48px">
			<PrimaryButton 
				onClick={saveMedia}
				isDisabled={!enableSave}
			>
				Save Media
			</PrimaryButton>
		</Stack>	
  	</PageContainer>  
  )
};
