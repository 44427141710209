import { React, useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { Stack, Autocomplete, TextField } from '@mui/material';
import { TablePagination } from '@material-ui/core';
import { FormLabel, BodyText, IconButton, Modal } from "@hm-group/fabric-components/brand/hm";
import { CloseIcon } from "@hm-group/fabric-icons";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import PageContainer from '../../components/PageContainer';
import ContactsList from './ContactsList';

export default function Contacts({ selectedShowroomID, submenu}) {
  const [successMessageModal, setSuccessMessageModal] = useState();
  const [contacts, setContacts] = useState({});
  const [filterData, setFilterData] = useState({});
  const [allMediaLabels, setAllMediaLabels] = useState([]);
  const [allOccupations, setAllOccupations] = useState([]);
  const [allInterests, setAllInterests] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [allMedias, setAllMedias] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const location = useLocation();
  const { authAxiosRequest } = useAuthAxiosRequest();

  // On component load
  useEffect( () => {
    if(location.state) {
      setSuccessMessageModal(location.state);
      window.history.replaceState({}, '/contacts');
    }
    
    const getAllMediaLabels = async() => {
      const response = await authAxiosRequest("/media/getAllMediaLabelLU");
      setAllMediaLabels(response.data.content);
    };

    const getAllOccupations = async() => {
      const response = await authAxiosRequest("/getContactLabels");
      setAllOccupations(response.data.map(occupation => ({ value: occupation.contactLabelId, label: occupation.contactLabelName})));
    };

    const getAllInterests = async() => {
      const response = await authAxiosRequest("/getInterestedIn");
      setAllInterests(response.data.map(interest => ({ value: interest.interestedInId, label: interest.interestedIn})));
    };

    getAllMediaLabels();
    getAllOccupations();
    getAllInterests();
  },[])

  useEffect( () => {
    authAxiosRequest("/getMedias/"+selectedShowroomID)
    .then(response => {
      setAllMedias(response.data.map(media => ({ value: media.mediaId, label: media.mediaName})))
    })
    .catch(error => {
      console.log(error);
    })

    authAxiosRequest('/contact/getAllContacts', {
      params: {
        'showroomId': selectedShowroomID,
        'active': 'not(contains(N))',
        'size': 10000,
        "sort": "firstName,asc"
      }
    }).then(response => {
      setAllContacts(response.data.content.map(contact => ({ value:contact.contactId, label: `${contact.firstName} ${contact.lastName}`})));
    })
    .catch(error => {
      console.log(error);
    })    
  },[selectedShowroomID])

  // Get users whenever the props change
  useEffect(() => {
    if (selectedShowroomID && selectedShowroomID) {
      var params = new URLSearchParams();
      
      // mandatory params

      params.append("showroomId", selectedShowroomID);
      params.append("active", 'not(contains(N))');
      params.append("size", rowsPerPage);
      params.append("page", page);
      params.append("sort", "firstName,asc");

      // filter fields params

      if (filterData.contacts) {
        filterData.contacts.forEach(selectedContactName => {
          allContacts.forEach(contact => {
            if(contact.label === selectedContactName) {
              params.append("contactId", contact.value);
            }
          })
        })
      }

      if (filterData.medias) {
        filterData.medias.forEach(selectedMediaName => {
          allMedias.forEach(media => {
            if(media.label === selectedMediaName) {
              params.append("mediaId", media.value);
            }
          })
        })
      }

      if (filterData.mediaLabels) {
        filterData.mediaLabels.forEach(selectedMediaLabelName => {
          allMediaLabels.forEach(mediaLabel => {
            if(mediaLabel.mediaLabelName === selectedMediaLabelName) {
              params.append("media.mediaLabel", mediaLabel.mediaLabelId);
            }
          })
        })
      }

      if (filterData.occupations) {
        filterData.occupations.forEach(selectedOccupation => {
          params.append("contactlabelId", `contains(${selectedOccupation})`);
        })
      }

      if (filterData.interests) {
        filterData.interests.forEach(selectedInterest => {
          params.append("interestedInId", `contains(${selectedInterest})`);
        })
      }

      const getUsers = () => {
        authAxiosRequest('/contact/getAllContacts', { params: params })
        .then(response => {
          setContacts(response.data);
        })  
        .catch(error => {
          console.log(error);
        })
      }

      getUsers();
    }
  },[page, rowsPerPage, selectedShowroomID, filterData])
  
  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {successMessageModal && (
         <Modal
          isOpen={successMessageModal}
          heading="Success"
          content={<BodyText dangerouslySetInnerHTML={{__html: successMessageModal}}/>}
          closeLabel="Close"
          size="medium"
          onClose={() => setSuccessMessageModal()}
        />
      )}
    
      <PageContainer title="Contacts" submenu={submenu}>
        <Stack direction="row" spacing={2} paddingBottom={4} alignItems="start" justifyContent="space-between">
          <Stack direction="row" spacing={2} flexGrow={2} className="contacts_filter-fields">
            <div>
              <FormLabel>Contact name(s)</FormLabel>
              {/* TODO: Search doesn't work in this Autocomplete, it's showing incorrect suggestions in the dropdown list  */}
              <Autocomplete
                multiple
                classes={{
                  option: 'hm-select-options-text'
                }}
                disabled={allContacts.length === 0}
                id="contacts"
                value={filterData.contacts ? filterData.contacts : []}
                options={allContacts.map((option) => option.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="hm-text-field"
                    placeholder="Search..."
                  />
                )}
                onChange={(event, newValue) => {
                  setFilterData({...filterData, contacts: newValue})
                  setPage(0);
                }}
              />
            </div>

            <div>
              <FormLabel>Media name(s)</FormLabel>

              <Autocomplete
                multiple
                classes={{
                  option: 'hm-select-options-text'
                }}
                disabled={allMedias.length === 0}
                id="medias"
                value={filterData.medias ? filterData.medias : []}
                options={allMedias.map((option) => option.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="hm-text-field"
                    placeholder="Search..."
                  />
                )}
                onChange={(event, newValue) => {
                  setFilterData({...filterData, medias: newValue})
                  setPage(0);
                }}
              />
            </div>

            <div>
              <FormLabel>Media label(s)</FormLabel>

              <Autocomplete
                multiple
                classes={{
                  option: 'hm-select-options-text'
                }}
                disabled={allMediaLabels.length === 0}
                id="media-labels"
                value={filterData.mediaLabels ? filterData.mediaLabels : []}
                options={allMediaLabels.map((option) => option.mediaLabelName)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="hm-text-field"
                    placeholder="Search..."
                  />
                )}
                onChange={(event, newValue) => {
                  setFilterData({...filterData, mediaLabels: newValue})
                  setPage(0);
                }}
              />
            </div>

            <div>
              <FormLabel>Occupation(s)</FormLabel>

              <Autocomplete
                multiple
                classes={{
                  option: 'hm-select-options-text'
                }}
                disabled={allOccupations.length === 0}
                id="occupations"
                value={filterData.occupations ? filterData.occupations : []}
                options={allOccupations.map((option) => option.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="hm-text-field"
                    placeholder="Search..."
                  />
                )}
                onChange={(event, newValue) => {
                  setFilterData({...filterData, occupations: newValue})
                  setPage(0);
                }}
              />
            </div>

            <div>
              <FormLabel>Interest(s)</FormLabel>

              <Autocomplete
                multiple
                classes={{
                  option: 'hm-select-options-text'
                }}
                disabled={allInterests.length === 0}
                id="interests"
                value={filterData.interests ? filterData.interests : []}
                options={allInterests.map((option) => option.label)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="hm-text-field"
                    placeholder="Search..."
                  />
                )}
                onChange={(event, newValue) => {
                  setFilterData({...filterData, interests: newValue})
                  setPage(0);
                }}
              />
            </div>
          </Stack>

          <div style={{ paddingTop: '24px'}}>
            <IconButton
              onClick={() => setFilterData({})}
              variant="primary"
              type="button"
              title="Clear all filter fields"
              iconElement={<CloseIcon size='small' />}
            />
          </div>
        </Stack>

        <ContactsList
          contacts={contacts.content}
          mediaLabels={allMediaLabels}
        />

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={(contacts && contacts.totalElements) ? contacts.totalElements : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </PageContainer>
    </>
  );
}
