import React from 'react';

import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

import { msalConfig, msalScopes } from './AuthConfig';

export default function MsalWrapper({ children }) {
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={msalScopes}
                // TODO: Create pages for error and loading
                errorComponent={() => <div>Error</div>}
                loadingComponent={() => <div>Loading</div>}
            >
                {children}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}
