import axios from 'axios';
import { useAccount, useMsal } from '@azure/msal-react';

import { msalScopes } from '../auth/AuthConfig';

export const useAuthAxiosRequest = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const getAccessToken = async () => {
    if (!account) return null;

    try {
      const { accessToken } = await instance.acquireTokenSilent({
        ...msalScopes,
        account: account,
      });

      return accessToken;
    } catch (err) {
      // TODO: Add proper client side logging
      console.log(err);
    }

    return null;
  };

  const authAxiosRequest = async (
    endpoint,
    config,
  ) => {
    const accessToken = await getAccessToken();
    let authorizationHeaderVal = '';

    if (accessToken) {
      authorizationHeaderVal = `${accessToken}`;
    }

    const updatedConfig = {
      ...config,
      headers: { ...config?.headers },
    };

    if (authorizationHeaderVal) {
      updatedConfig.headers = {
        ...updatedConfig.headers,
        // TODO: Send access token with Bearer
        ['Access-Token']: authorizationHeaderVal,
      };
    }

    return axios({
        url: endpoint,
        baseURL: process.env.REACT_APP_BACKEND_URI,
        ...updatedConfig,
    });
  };

  return { authAxiosRequest };
};