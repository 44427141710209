import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";

import { Stack, Container } from '@mui/material';
import { BodyText, HeadingMedium, HeadingSmall, InlineLink } from "@hm-group/fabric-components/brand/hm";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import formatDate from '../../utils/formatDate';

import AddSamplesToLoan from './AddSamplesToLoan';
import SamplesList from './SamplesList';

export default function LoanDetails({ selectedShowroomID }) {
  const [shouldShowLoanPage, setShouldShowLoanPage] = useState();
  const [sampleList, setSampleList] = useState([]);
  const [loanInfo, setLoanInfo] = useState({});
  const [contactInfo, setContactInfo] = useState();
  const [updatedByName, setUpdatedByName] = useState();

  const {loanID} = useParams();
  const { authAxiosRequest } = useAuthAxiosRequest();

  // Get Loan data on load
  useEffect( () => {
    const getLoanData = () => {
      authAxiosRequest('/loan/getAllLoans', { params: { 'loanId': loanID }})
      .then(response => {
        setLoanInfo({
          showroomID: response.data.content[0].showroomId,
          loanID: loanID,
          contactID: response.data.content[0].contactId,
          createdDate: formatDate(response.data.content[0].createdDate),
          createdByID: response.data.content[0].createdById,
          updatedDate: response.data.content[0].updatedDate ? formatDate(response.data.content[0].updatedDate) : null,
          updatedByID: response.data.content[0].updatedById,
          lastReturnDate: formatDate(response.data.content[0].lastReturnDate),
        });

        setSampleList(response.data.content[0].samples.filter(sample => sample.loanSample.sampleStatudId !== 4).reverse());
      })  
      .catch(error => {
        console.log(error);
      })
    }

    getLoanData();
  },[loanID])

  // Get createdByName when loanInfo.createdByID changes
  useEffect(() => {
    if (loanInfo.createdByID) {
      const getUserName = () => {
        authAxiosRequest('/user/getAllUsers', { params: { 'userId': loanInfo.createdByID } })
        .then(response => {
          setLoanInfo({ ...loanInfo, createdByName: `${response.data.content[0].firstName} ${response.data.content[0].lastName}`});
        })  
        .catch(error => {
          console.log(error);
        })
      }

      getUserName();
    }
  },[loanInfo.createdByID])
  
  // Get updatedByName when loanInfo.updatedByID changes
  useEffect(() => {
    if (loanInfo.updatedByID) {
      const getUserName = () => {
        authAxiosRequest('/user/getAllUsers', { params: { 'userId': loanInfo.updatedByID } })
        .then(response => {
          setUpdatedByName(`${response.data.content[0].firstName} ${response.data.content[0].lastName}`);
        })  
        .catch(error => {
          console.log(error);
        })
      }

      getUserName();
    }
  },[loanInfo.updatedByID])

  // Get contact and media info when loanInfo.contactID changes
  useEffect( () => {
    if (loanInfo.contactID) {
      const getContactInfo = () => {
        authAxiosRequest('/contact/getAllContacts', { params: { 'contactId': loanInfo.contactID }})
        .then(response => {
          const emailAddresses = [response.data.content[0].email, response.data.content[0].media?.email].filter(email => email && email.length > 0);

          const addressLine1 = (response.data.content[0].deliveryAddressLine1 || '') + (response.data.content[0].deliveryAddressLine2 ? ` (${response.data.content[0].deliveryAddressLine2})` : '');

          const addressLine2 = (response.data.content[0].deliveryAddressLine3 ? `${response.data.content[0].deliveryAddressLine3} ` : '') + (response.data.content[0].deliveryAddressLine4 || '');

          const fullAddress = [
            addressLine1,
            addressLine2,
            response.data.content[0].countryCodeDelivery
          ].filter(address => address && address.length > 1).join('<br/>');

          setContactInfo({
            ...contactInfo,
            name: `${response.data.content[0].firstName} ${response.data.content[0].lastName}`,
            email: response.data.content[0].email,
            emailAddresses,
            media: response.data.content[0].media?.mediaName,
            address: fullAddress,
            phoneNumber: response.data.content[0].phone,
          })
        })  
        .catch(error => {
          console.log(error);
        })
      }

      getContactInfo();
    }
  },[loanInfo.contactID])

  useEffect( () => {
    if (loanInfo.showroomID && loanInfo.showroomID.toString() === selectedShowroomID.toString()) {
      setShouldShowLoanPage(true);
    } else {
      setShouldShowLoanPage(false);
    }
  },[loanInfo.showroomID, selectedShowroomID])

  // Refresh loan data
  const refreshLoanData = () => {
    authAxiosRequest('/loan/getAllLoans', { params: { 'loanId': loanID  }})
    .then(response => {
      setLoanInfo({
        ...loanInfo,
        updatedDate: response.data.content[0].updatedDate ? formatDate(response.data.content[0].updatedDate) : null,
        updatedByID: response.data.content[0].updatedById,
        lastReturnDate: formatDate(response.data.content[0].lastReturnDate),
      });

      setSampleList(response.data.content[0].samples.filter(sample => sample.loanSample.sampleStatudId !== 4).reverse());
    })  
    .catch(error => {
      console.log(error);
    })
  }

  if (loanInfo.showroomID && contactInfo) {
    return (
      <Container className='tabBox' maxWidth={false} style={{ paddingBottom: '24px' }}>
        {!shouldShowLoanPage ? (
          <div style={{paddingTop: '40px', textAlign: 'center'}}>
            <HeadingMedium isMarginless>Loan does not belong to the chosen showroom</HeadingMedium>
            <BodyText>Please choose a different showroom from the dropdown above.</BodyText>
          </div>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding="15px 25px"
              marginX="-25px"
              style={{ background: 'rgb(243, 237, 232)' }}
            >
              <div>
                <HeadingSmall>Loan no. {loanID}</HeadingSmall>
                <HeadingSmall isMarginless Tag="p">
                  Contact: {contactInfo.name} <InlineLink href={`/contact/${String(loanInfo.contactID)}`} >( Edit )</InlineLink>
                </HeadingSmall>
              </div>
              <div>
                <BodyText>
                  Created: {loanInfo.createdDate} by {loanInfo.createdByName}<br/>
                  Last updated: {loanInfo.updatedDate ? `${loanInfo.updatedDate} by ${updatedByName}` : '-'}
                </BodyText>
                <BodyText>
                  Last return date: {loanInfo.lastReturnDate}
                </BodyText>
              </div>
            </Stack>
            
            <AddSamplesToLoan
              loanID={loanInfo.loanID}
              selectedShowroomID={selectedShowroomID}
              refreshLoanData={refreshLoanData}
              style={{ marginTop: '36px', marginBottom: '36px'}}
            />

            <SamplesList
              title="Samples"
              samples={sampleList}
              refreshLoanData={refreshLoanData}
              loanInfo={loanInfo}
              contactInfo={contactInfo}
              selectedShowroomID={selectedShowroomID}
            />
          </>
        )}
      </Container>
    );
  } else {
    return null;
  }
}
