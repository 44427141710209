import React from 'react';
import ReactDOM from 'react-dom';

import MsalWrapper from './auth/MsalWrapper';
import UserAccessCheck from './UserAccessCheck';

import App from './App';

ReactDOM.render(
  <MsalWrapper>
    <UserAccessCheck>
      <App />
    </UserAccessCheck>
  </MsalWrapper>,

  document.getElementById('root'),
);