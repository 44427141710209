import {React, useState, useEffect} from 'react';
import validator from 'validator';
import { useParams, useNavigate } from "react-router-dom";

import { Grid } from '@material-ui/core';
import { Stack, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { HeadingSmall, BodyText, BannerNotification, Modal, PrimaryButton, SecondaryButton, FormLabel, TextInput } from "@hm-group/fabric-components/brand/hm";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import PageContainer from '../../components/PageContainer';

export default function MediaInfo({ selectedShowroomID, submenu }) {
  const [shouldHideMediaInfo, setShouldHideMediaInfo] = useState();
  const [mediaInfo, setMediaInfo] = useState({});
  const [initialMediaInfo, setInitialMediaInfo] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState();
  const [countryList, setCountryList] = useState([]);
  const [mediaLabels, setMediaLabels] = useState([]);
  const [showUpdateMediaSuccessModal, setShowUpdateMediaSuccessModal] = useState(false);
  const [showDeleteMediaConfirmationModal, setShowDeleteMediaConfirmationModal] = useState(false);
  const [showMediaDeletionFailure, setShowMediaDeletionFailure] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [connectedContacts, setConnectedContacts] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);
 
  const {mediaID} = useParams();
  const navigate = useNavigate();
  const { authAxiosRequest } = useAuthAxiosRequest();

  useEffect( () => {
    authAxiosRequest('/medias/'+ mediaID)
    .then(response => {
      setInitialMediaInfo({
        mediaName: response.data[0][1],
        defaultMediaLabel: response.data[0][2],
        email: response.data[0][3],
        phone: response.data[0][4],
        website: response.data[0][5],
        additionalInformation: response.data[0][6],
        deliveryAddress: response.data[0][9],
        deliveryAddress1: response.data[0][10],
        deliveryAddress2: response.data[0][11],
        deliveryAddress3: response.data[0][12],
        defaultCountryDelivery: response.data[0][13],
        mailingAddress: response.data[0][14],
        mailingAddress1: response.data[0][15],
        mailingAddress2: response.data[0][16],
        mailingAddress3: response.data[0][17],
        defaultCountryMailing: response.data[0][18],
        showroomID: response.data[0][7],
      });
    })
    .catch(error => {
      console.log(error);
    })
  },[mediaID])

  useEffect( () => {
    setMediaInfo(initialMediaInfo);
  },[initialMediaInfo])

  // On component load
  useEffect( () => {
    const getCountries = async() => {
      const response = await authAxiosRequest("/countries");
      setCountryList(response.data.map(country => ({ id: country.GeographicalCountryId, label: country.CountryOfDeliveryName})));
    };

    const getMediaLabels = async() => {
      const response = await authAxiosRequest("/getMediaLabels");  
      setMediaLabels(response.data.map(mediaLabel => ({ value: mediaLabel.mediaLabelId, label: mediaLabel.mediaLabelName})));
    };

    getCountries();
    getMediaLabels();
  },[])

  useEffect( () => {
    if (initialMediaInfo.showroomID && initialMediaInfo.showroomID.toString() === selectedShowroomID.toString()) {
      setShouldHideMediaInfo(false);
    } else {
      setShouldHideMediaInfo(true);
    }
  },[initialMediaInfo.showroomID, selectedShowroomID])

  useEffect( () => {
    if(JSON.stringify(mediaInfo) !== JSON.stringify(initialMediaInfo) && !phoneNumberError && !emailError) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  },[mediaInfo, emailError, phoneNumberError])

  const validatePhoneNumber = ({ isValidNumber }) => {
    if (mediaInfo.phone && !isValidNumber) {
      setPhoneNumberError('Invalid Phone Number');
    } else {
      setPhoneNumberError(undefined);
    }
  }

  const saveMediaChanges = () => {
    const mediaDetails = {
      "mediaId": mediaID,
      "mediaName": mediaInfo.mediaName,
      "email": mediaInfo.email,
      "phone": mediaInfo.phone,
      "additionalInfo": mediaInfo.additionalInformation,
      "website": mediaInfo.website,
      "deliveryAddressLine1": mediaInfo.deliveryAddress,
      "deliveryAddressLine2": mediaInfo.deliveryAddress1,
      "deliveryAddressLine3": mediaInfo.deliveryAddress2,
      "deliveryAddressLine4": mediaInfo.deliveryAddress3,
      "countryCodeDelivery": mediaInfo.defaultCountryDelivery,
      "mailingAddressLine1": mediaInfo.mailingAddress,
      "mailingAddressLine2": mediaInfo.mailingAddress1,
      "mailingAddressLine3": mediaInfo.mailingAddress2,
      "mailingAddressLine4": mediaInfo.mailingAddress3,
      "countryCodeMailing": mediaInfo.defaultCountryMailing,
      "mediaLabel": mediaInfo.defaultMediaLabel
    }

    authAxiosRequest('/updateMedia', {
      method: 'POST',
      data: mediaDetails,
      "headers": {
        "content-type": "application/json",
      }
    })		
    .then(response => {
      setShowUpdateMediaSuccessModal(true);
      setInitialMediaInfo(mediaInfo);
    })
    .catch(error => {
      setShowErrorModal(true);
    })
  }

  const deleteMedia = () => {
    setShowDeleteMediaConfirmationModal(false);

    authAxiosRequest('/deleteMedia/'+mediaID, { method: 'POST' })
    .then(response => {
      let contactList='';

      for(const contactArray in response.data ){
        if(contactArray === "NoContacts"){
          // has no connected contacts
          navigate('/contacts/', { state: `Media <b>${initialMediaInfo.mediaName}</b> was successfully deleted. There were no Contacts connected to this Media.`});
        } else if(contactArray === "Return") {
          // has contacts connected that have returned loans only
          for(var i=0; i < response.data.Return.length; i++){
            if(i === (response.data.Return.length-1)){
              contactList = contactList + response.data.Return[i];
            } else {
              contactList = contactList + response.data.Return[i]+", ";
            }     
          }
          navigate('/contacts/', { state: `Media <b>${initialMediaInfo.mediaName}</b> was successfully deleted. Connected Contacts deleted: ${contactList}`});
        } else if(contactArray === "NoLoans") {
          // has contacts connected that have no loans
          for(var i=0; i < response.data.NoLoans.length; i++){
            if(i === (response.data.NoLoans.length-1)){
              contactList = contactList + response.data.NoLoans[i];
            } else {
              contactList = contactList + response.data.NoLoans[i]+", ";
            }     
          }
          navigate('/contacts/', { state: `Media <b>${initialMediaInfo.mediaName}</b> was successfully deleted. Connected Contacts deleted: ${contactList}`});
        } else if(contactArray === "UnReturn"){
          // has contacts connected that have unreturned loans
          for(var i=0; i < response.data.UnReturn.length; i++){
            if(i === (response.data.UnReturn.length-1)){
              contactList = contactList + response.data.UnReturn[i];
            } else {
              contactList = contactList + response.data.UnReturn[i]+", ";
            }     
          }
          setConnectedContacts(contactList);
          setShowMediaDeletionFailure(true);
        } else {
          setShowErrorModal(true);
        }
      }
    })
    .catch(error => {
      setShowErrorModal(true);
    })
  }

  if (initialMediaInfo.showroomID) {
    return (
      <PageContainer title={shouldHideMediaInfo ? 'Invalid media' : `Media: ${initialMediaInfo.mediaName}`} submenu={submenu}>
        {shouldHideMediaInfo && (
          <div>This Media is not connected to your showroom.</div>
        )}

        {showDeleteMediaConfirmationModal && (
          <Modal
            isOpen={showDeleteMediaConfirmationModal}
            heading="Delete Media"
            content={<BodyText dangerouslySetInnerHTML={{__html: `Are you sure you want to delete the Media <b>${initialMediaInfo.mediaName}</b> ?<br/>
              <i> Note: All the Contacts connected to the media will be deleted. </i>`}} />
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowDeleteMediaConfirmationModal(!showDeleteMediaConfirmationModal)}
            actions={
              <>
                <PrimaryButton onClick={() => deleteMedia()}>Yes</PrimaryButton>
                <SecondaryButton onClick={() => setShowDeleteMediaConfirmationModal(false)}>No</SecondaryButton>
              </>
            }
          />
        )}

        {showMediaDeletionFailure && (
          <Modal
            isOpen={showMediaDeletionFailure}
            heading="Can't delete Media"
            content={
              <>
                <BodyText dangerouslySetInnerHTML={{__html: `The Media <b>${initialMediaInfo.mediaName}</b> can't be deleted since at least one Contact connected to it has unreturned loans.`}}/>
                <br />
                <BodyText dangerouslySetInnerHTML={{__html: `<b>Contacts with unreturned loans:</b>
                  <br />
                  ${connectedContacts}`}}
                />
              </>
            
            }
            closeLabel="Close"
            size="medium"
            onClose={() => setShowMediaDeletionFailure(!showMediaDeletionFailure)}
          />
        )}

        {showUpdateMediaSuccessModal && (
          <Modal
            isOpen={showUpdateMediaSuccessModal}
            heading="Changes Saved"
            content={<BodyText dangerouslySetInnerHTML={{__html: `The Media <b>${mediaInfo.mediaName}</b> was successfully updated!`}} />}
            closeLabel="Close"
            size="medium"
            onClose={() => setShowUpdateMediaSuccessModal(!showUpdateMediaSuccessModal)}
          />
        )}

        {showErrorModal && (
          <Modal
            isOpen={showErrorModal}
            heading="FAILURE"
            content="Internal Server Error Occurred!"
            closeLabel="Close"
            size="medium"
            onClose={() => setShowErrorModal(false)}
          />
        )}

        {!shouldHideMediaInfo && (
          <>
            <BannerNotification 
							severity="info"
							message="Media information must be general information from the Media, e.g Phone number to Switchboard, E-mail = info@media.com etc. 
              If it’s individual information, it must be registered on the specific Contact."
							shouldHideCloseButton
						/>


            <Grid container spacing={6}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <HeadingSmall>Contact information</HeadingSmall>

                  <TextInput
                    label="Media Name"
                    name="mediaName"
                    isRequired
                    value={mediaInfo.mediaName}
                    onChange={e => setMediaInfo({...mediaInfo, mediaName: e.target.value})}
                  />

                  <FormControl required>
                    <InputLabel id="mediaLabel-label">Media Label</InputLabel>
                    <Select
                      id="mediaLabel"
                      label="Media Label"
                      value={mediaInfo.defaultMediaLabel ? mediaInfo.defaultMediaLabel : ''}
                      onChange={e => setMediaInfo({...mediaInfo, defaultMediaLabel: e.target.value})}
                    >
                      {mediaLabels.map(mediaLabel => <MenuItem value={mediaLabel.value}>{mediaLabel.label}</MenuItem>)}
                    </Select>
                  </FormControl>

                  <TextInput
                    label="Email"
                    name="email"
                    value={mediaInfo.email}
                    placeholder="example@email.com"
                    inputMode="email"
                    onChange={e => {
                      setMediaInfo({...mediaInfo, email: e.target.value});
                      if (e.target.value.length > 0 & !validator.isEmail(e.target.value)) {
                        setEmailError('This email format is not valid. Please enter a valid email format (example@email.com)')
                      } else {
                        setEmailError(undefined)
                      }
                    }}
                    isInvalid={emailError}
                    helperText={emailError}
                  />

                  <TextInput
                    label="Phone Number"
                    name="phoneNumber"
                    value={mediaInfo.phone}
                    placeholder='e.g. +1234567890'
                    inputMode="tel"
                    onChange={e => setMediaInfo({...mediaInfo, phone: e.target.value})}
                    helperText="Please enter a valid phone number. It should start with a '+' followed by country code and contain numbers only."
                    // onErrorCallBack={validatePhoneNumber}
                    // error={phoneNumberError}
                  />

                  <TextInput
                    label="Website"
                    name="website"
                    inputMode="url"
                    value={mediaInfo.website}
                    onChange={e => setMediaInfo({...mediaInfo, website: e.target.value})}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                <HeadingSmall>Additional information</HeadingSmall>
                
                <FormLabel>Additional information</FormLabel>
                <TextField
                  style={{background: "white", marginTop: '4px'}}
                  name="additionalInformation"
                  multiline
                  rows={5} cols={15}
                  value={mediaInfo.additionalInformation}
                  onChange={e => setMediaInfo({...mediaInfo, additionalInformation: e.target.value})}
                />
                
                <BodyText>
                  In Additional Info you are only allowed to write relevant facts from an objective point of view.<br/>
                  Sensitive information or personal reflections are not allowed.
                </BodyText>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={6} style={{ paddingTop: '24px'}}>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <HeadingSmall>Delivery information</HeadingSmall>

                  <TextInput
                    label="Delivery Address"
                    name="deliveryAddress"
                    value={mediaInfo.deliveryAddress}
                    onChange={e => setMediaInfo({...mediaInfo, deliveryAddress: e.target.value})}
                  />

                  <TextInput
                    label="Apartment,suite,etc."
                    name="deliveryAddress1"
                    value={mediaInfo.deliveryAddress1}
                    onChange={e => setMediaInfo({...mediaInfo, deliveryAddress1: e.target.value})}
                  />

                  <TextInput
                    label="Zip code"
                    name="deliveryAddress2"
                    value={mediaInfo.deliveryAddress2}
                    onChange={e => setMediaInfo({...mediaInfo, deliveryAddress2: e.target.value})}
                  />

                  <TextInput
                    label="City"
                    name="deliveryAddress3"
                    value={mediaInfo.deliveryAddress3}
                    onChange={e => setMediaInfo({...mediaInfo, deliveryAddress3: e.target.value})}
                  />

                  <FormControl>
                    <InputLabel id="defaultCountryDelivery-label">Country</InputLabel>
                    <Select
                      id="defaultCountryDelivery"
                      label="Country"
                      value={mediaInfo.defaultCountryDelivery ? mediaInfo.defaultCountryDelivery : ''}
                      onChange={e => setMediaInfo({...mediaInfo, defaultCountryDelivery: e.target.value})}
                    >
                      {countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="column" spacing={2}>
                  <HeadingSmall>Mailing information</HeadingSmall>

                  <TextInput
                    label="Mailing Address"
                    name="mailingAddress"
                    value={mediaInfo.mailingAddress}
                    onChange={e => setMediaInfo({...mediaInfo, mailingAddress: e.target.value})}
                  />

                  <TextInput
                    label="Apartment,suite,etc."
                    name="mailingAddress1"
                    value={mediaInfo.mailingAddress1}
                    onChange={e => setMediaInfo({...mediaInfo, mailingAddress1: e.target.value})}
                  />

                  <TextInput
                    label="Zip code'"
                    name="mailingAddress2"
                    value={mediaInfo.mailingAddress2}
                    onChange={e => setMediaInfo({...mediaInfo, mailingAddress2: e.target.value})}
                  />

                  <TextInput
                    label="City"
                    name="mailingAddress3"
                    value={mediaInfo.mailingAddress3}
                    onChange={e => setMediaInfo({...mediaInfo, mailingAddress3: e.target.value})}
                  />

                  <FormControl>
                    <InputLabel id="defaultCountryMailing-label">Country</InputLabel>
                    <Select
                      id="defaultCountryMailing"
                      label="Country"
                      value={mediaInfo.defaultCountryMailing ? mediaInfo.defaultCountryMailing : ''}
                      onChange={e => setMediaInfo({...mediaInfo, defaultCountryMailing: e.target.value})}
                    >
                      {countryList.map(country => <MenuItem value={country.label}>{country.label}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2} paddingTop="48px">
              <PrimaryButton
                onClick={saveMediaChanges}
                isDisabled={disableSaveButton}
              >
                Save Changes
              </PrimaryButton>
              <SecondaryButton onClick={() => setShowDeleteMediaConfirmationModal(true)}>
                Delete Media
              </SecondaryButton>
            </Stack>
          </>
        )}
      </PageContainer>
    )
  } else {
    return null
  }
}
