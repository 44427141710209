import { React } from 'react';

import { Stack  } from '@mui/material';
import { HeadingSmall, TextInput, ToggleSwitch } from "@hm-group/fabric-components/brand/hm";

export default function HMOnlineAccountSection({ contactInfo, setContactInfo }) {
  
  return (
    <Stack direction="column" spacing={2}>
      <HeadingSmall>H&M Online Account </HeadingSmall>

      <ToggleSwitch
        id="toggleswitch"
        onChange={e => {
          // When false - remove contactInfo.memberID
          setContactInfo({
            ...contactInfo,
            memberIDConsent: e.target.checked,
            memberID: null,
          })
        }}
        label="Contact consents to showroom storing their H&M Online Member ID."
        isChecked={contactInfo.memberIDConsent}
      />

      <TextInput
        label="Member ID"
        name="memberID"
        value={contactInfo.memberID || ''}
        onChange={e => setContactInfo({...contactInfo, memberID: e.target.value})}
        isDisabled={!contactInfo.memberIDConsent}
        helperText="Member ID can be found under 'My Account' in the H&M app or on the H&M Online."
      />
    </Stack>
  )
}
