
import { React, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import { useAuthAxiosRequest } from './hooks/useAuthAxiosRequest';
import AccessRestrictedPage from "./pages/AccessRestrictedPage";
import { Preamble } from "@hm-group/fabric-components/brand/hm";

function UserAccessCheck({ children }) {	
	const [userRole, setUserRole] = useState();

	const { authAxiosRequest } = useAuthAxiosRequest();
	// Get all logged in accounts from the MSAL local storage
	const { accounts } = useMsal();

	useEffect( () => {
		const loadUser = async() => {
			const response = await authAxiosRequest("/checkUserExist", {
				method: 'POST',
				data: { userName: accounts[0].username },
			});

			if(response !== undefined) {
				localStorage.setItem('user_id', response.data.user_id);
				localStorage.setItem('user_role', response.data.role);
				setUserRole(response.data.role);
			} else {
				setUserRole(null);
			}
		};

		loadUser();
	},[accounts]);

	if (userRole === 1 || userRole === 2) {
		return children;
	} else if (userRole === null || userRole === 0) {
		return (
			<AccessRestrictedPage
				title="ACCESS DENIED"
				text="You don't have access to Showroom Application."
			>
				<Preamble>
					To request access please contact Showroom responsible:
					<br />
					<a href='mailto:tinam.johansson@hm.com'>tinam.johansson@hm.com</a>
				</Preamble>
			</AccessRestrictedPage>
		)
	} else {
		// TODO: Style the Loading page, maybe add a spinner
		return <div>Loading Showroom application...</div>
	}
}

export default UserAccessCheck;