import React, {useState, useEffect} from 'react';

import { Stack } from '@mui/material';

import { BodyText, Modal, PrimaryButton, SecondaryButton, TextInput, TextArea, GhostButton, FormLabel, BannerNotification } from "@hm-group/fabric-components/brand/hm";
import { MailIcon, NewWindowIcon } from "@hm-group/fabric-icons/brand/hm";

import { useAuthAxiosRequest } from '../../hooks/useAuthAxiosRequest';
import formatDate from '../../utils/formatDate';

export default function LoanReceiptOptions({ samples, loanInfo, contactInfo, selectedShowroomID }) {
  const [showEmailPreview, setShowEmailPreview] = useState();
  const [emailData, setEmailData] = useState({
    subject: `Receipt for loan #${loanInfo.loanID}`,
    attachmentName: `Receipt_loan-${loanInfo.loanID}_${new Date().toISOString().split('T')[0]}.pdf`,
  });
  const [emailReceiversInvalid, setEmailReceiversInvalid] = useState();

  const [showroomInfo, setShowroomInfo] = useState({});
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isPrintingReceipt, setIsPrintingReceipt] = useState(false);

  const [successMessage, setSuccessMessage] = useState();
  const [error, setError] = useState({});

  const { authAxiosRequest } = useAuthAxiosRequest();

  // Set email recepients
  useEffect( () => {
    setEmailData({...emailData, receivers: contactInfo.emailAddresses.join(', ')});
  },[contactInfo])

  // Get showroom info
  useEffect(() => {
    const getShowroomInfo = () => {
      authAxiosRequest('/getAllShowrooms', {
        params: { 'showroomId': selectedShowroomID }
      })
      .then(response => {
        setShowroomInfo(response.data.content[0]);
      })  
      .catch(err => {
        console.log(err);
      })
    }

    getShowroomInfo();
  },[selectedShowroomID])

  // Validate email addresses on load and on change
  useEffect(() => {
    const validateEmails = (emails) => {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
      const emailList = emails.split(",").map(email => email.trim());
      const invalidEmails = emailList.filter(email => !emailRegex.test(email));
      setEmailReceiversInvalid(invalidEmails.length > 0);
    }

    if(emailData.receivers) {
      validateEmails(emailData.receivers);
    }
  }, [emailData.receivers]);  

  function createSingleReceiptData(samples, title, loanInstructions, samplesTitle, dateColumnTitle) {
    const refinedSamples = samples.map(sample => {
      let date;

      switch (samplesTitle) {
        case 'Lent':
          date = loanInfo.lastReturnDate;
          break;
        case 'Returned':
        case 'Gifted':
          date = formatDate(sample.loanSample.updatedDate);
          break;
        default:
          date = '-';
      }

      return {
        productName: sample.productClassification || '-',
        productNo: sample.productNumber || '-',
        section: sample.sectionName || '-',
        department: sample.departmentName || '-',
        price: sample.price ? `${sample.price} ${sample.currencyCode}` : '-',
        composition: sample.composition || '-',
        color: sample.colors || '-',
        size: sample.sizes || '-',
        date
      }
    });

    const receiptData = {
      title,
      loanInstructions: loanInstructions !== '' ? loanInstructions : null,
      samplesTitle,
      sampleAmount: refinedSamples.length,
      dateColumnTitle,
      samples: refinedSamples
    }

    return receiptData;
  }

  function createReceiptData() {
    const contactData = {
      name: contactInfo.name,
      media: contactInfo.media || '-',
      email: contactInfo.email || '-',
      phone: contactInfo.phoneNumber || '-',
      address: contactInfo.address || '-'
    }

    const loanData = {
      id: loanInfo.loanID,
      issueDate: loanInfo.createdDate,
      receiptDate: formatDate(new Date())
    }

    const hasShowroomContactData = showroomInfo.phoneNumber || showroomInfo.email || showroomInfo.address;

    const showroomContactData = hasShowroomContactData ? [
      showroomInfo.phoneNumber ? `<li>Phone number: ${showroomInfo.phoneNumber}</li>` : null,
      showroomInfo.email ? `<li>Email: ${showroomInfo.email}</li>` : null,
      showroomInfo.address ? `<li>Address: ${showroomInfo.address}</li>` : null,
    ].filter(contactLine => contactLine !== null).join('') : null;

    const lentSamples = samples.filter(sample => sample.loanSample.sampleStatudId === 2);
    const returnedSamples = samples.filter(sample => sample.loanSample.sampleStatudId === 3);
    const giftedSamples = samples.filter(sample => sample.loanSample.sampleStatudId === 1);

    let lentReceiptData = null;
    let returnReceiptData = null;
    let giftReceiptData = null;

    if (lentSamples.length > 0) {
      const loanInstructionsLentData = [
        `<p>All lent items must be returned within a period of ${showroomInfo.loanPeriodDays} days. Please return all items by ${loanInfo.lastReturnDate}.</p>`,
        showroomInfo.lentSamplesInstructions ? `<p>${showroomInfo.lentSamplesInstructions}</p>` : null,
        showroomInfo.additionalInfo ? `<p>${showroomInfo.additionalInfo}</p>` : null,
      ].filter(lentInfoLine => lentInfoLine !== null).join('');

      lentReceiptData = createSingleReceiptData(lentSamples, 'Lent receipt', loanInstructionsLentData, 'Lent', 'Return By');
    }

    if (returnedSamples.length > 0) {
      const loanInstructionsReturnData = showroomInfo.additionalInfo ? `<p>${showroomInfo.additionalInfo}</p>` : null;

      returnReceiptData = createSingleReceiptData(returnedSamples, 'Return receipt', loanInstructionsReturnData, 'Returned', 'Returned On');
    }

    if (giftedSamples.length > 0) {
        const loanInstructionsGiftData = [
          showroomInfo.giftSamplesInstructions ? `<p>${showroomInfo.giftSamplesInstructions}</p>` : null,
          showroomInfo.additionalInfo ? `<p>${showroomInfo.additionalInfo}</p>` : null,
        ].filter(giftInfoLine => giftInfoLine !== null).join('');

      giftReceiptData = createSingleReceiptData(giftedSamples, 'Gift receipt', loanInstructionsGiftData, 'Gifted', 'Gifted On');
    }

    const receiptData = {
      contact: contactData,
      loan: loanData,
      receipts: [lentReceiptData, returnReceiptData, giftReceiptData].filter(receipt => receipt !== null),
      showroomContact: showroomContactData,
      legal: "We register your personal data to our system to manage the use of showroom samples and existing media/business relations. For more information about our processing of personal data, please read H&M Group Privacy Notice External Partners on https://hmgroup.com/legal-notice/."
    }
    
    return receiptData;
  }

  function printReceipt() {
    setIsPrintingReceipt(true);
    setError({});

    const receiptData = createReceiptData();

    authAxiosRequest('/tool/generatePdf', {
      method: 'POST',
      data: receiptData,
      responseType: 'blob',
      "headers": {
        "content-type": "application/json"
      }
    }).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // OPEN pdf in new tab
      window.open(url, '_blank');

      setIsPrintingReceipt(false);
    }).catch(err => {
      setError({
        type: 'printReceipt',
        message: 'Something went wrong. Please try again.'
      })
      setIsPrintingReceipt(false);
    })
  }

  function sendEmail() {
    setIsSendingEmail(true);
    setError({});

    const receiptData = createReceiptData();

    const finalEmailData = {
      ...emailData,
      receivers: emailData.receivers.split(",").map(email => email.trim()),
      body: emailData.body.replace(/\n/g, '<br>'),
    }

    authAxiosRequest('/tool/email', {
      method: 'POST',
      data: {
        emailProp: finalEmailData,
        data: receiptData
      },
      "headers": {
        "content-type": "application/json"
      }
    }).then(response => {
      setShowEmailPreview(false)
      setIsSendingEmail(false);

      setSuccessMessage(`Receipt was sent to: ${emailData.receivers}.`);
    }).catch(err => {
      setError({
        type: 'sendEmail',
        message: 'Something went wrong. Please try again.'
      })
      setIsSendingEmail(false);
    })
  }

  return (
    <>
      {error.type === 'printReceipt' && (
        <Modal
          isOpen={error.type === 'printReceipt'}
          closeLabel="Close"
          heading="Printing failed"
          onClose={() => setError({})}
          content={<BodyText>{error.message}</BodyText>}
          actions={<PrimaryButton onClick={() => setError({})}>OK</PrimaryButton>}
        />
      )}

      {showEmailPreview && (
        <Modal
          isOpen={showEmailPreview}
          closeLabel="Close"
          size="medium"
          heading="Send Receipt"
          onClose={() => {
            setShowEmailPreview(false);
            setError({});
          }}
          content={
            <Stack direction="column" spacing={1}>
              {error.type === 'sendEmail' && (
                <BannerNotification 
                  severity="error"
                  message={error.message}
                  closeLabel="Close"
                />
              )} 

              <Stack direction="row" spacing={3}>
                <div style={{ flexGrow: '1'}}>
                  <TextInput
                    label="From:"
                    isDisabled
                    value='noreply@hmgroupshowroom.com'
                  />
                </div>
                <div style={{ flexGrow: '1'}}>
                  <FormLabel>Attachments:</FormLabel>
                  <GhostButton
                    onClick={() => printReceipt()}
                    iconElement={<NewWindowIcon size={('Icon size', 'small')} />}
                  >
                    {emailData.attachmentName}  
                  </GhostButton>
                </div>
              </Stack>

              <TextInput
                label="To:"
                value={emailData.receivers}
                onChange={e => setEmailData({...emailData, receivers: e.target.value})}
                isInvalid={emailReceiversInvalid}
                helperText={emailReceiversInvalid ? 'Please enter the email address(es) in the following format: example@email.com, another-example@email.com' : null}
                placeholder='example@email.com, another-example@email.com'
                isRequired
              />

              <TextInput
                label="Subject:"
                value={emailData.subject}
                onChange={e => setEmailData({...emailData, subject: e.target.value})}
                isRequired
              />

              <TextArea
                label="Body:"
                value={emailData.body}
                onChange={e => setEmailData({...emailData, body: e.target.value})}
                isRequired
              />
            </Stack>
          }
          actions={
            <PrimaryButton
              type="submit"
              isDisabled={!emailData.receivers || emailReceiversInvalid || !emailData.subject || !emailData.body}
              isLoading={isSendingEmail}
              onClick={() => sendEmail()}
            >
              Send
            </PrimaryButton>
          }
        />
      )}

      {successMessage && (
        <Modal
          isOpen={successMessage}
          heading="SUCCESS"
          closeLabel="Close"
          size="medium"
          onClose={() => setSuccessMessage()}
          content={<BodyText>{successMessage}</BodyText>}
        />
      )}
      
      <Stack direction="row" justifyContent="right" spacing={1}>
        <SecondaryButton
          isDisabled={samples.length === 0}
          onClick={() => printReceipt()}
          isLoading={isPrintingReceipt}
          iconPosition="start"
          iconElement={<NewWindowIcon size={('Icon size', 'small')} />}
        >
          Print
        </SecondaryButton>

        <SecondaryButton
          isDisabled={samples.length === 0}
          onClick={() => setShowEmailPreview(true)}
          iconPosition="start"
          iconElement={<MailIcon size={('Icon size', 'small')} />}
        >
          Email
        </SecondaryButton>
      </Stack>
    </>
  )
}
