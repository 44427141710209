/**
 * Formats a date object into a string.
 * @param {Date} date - The date object to format.
 * @returns {string} The formatted date string.
 */

function formatDate(date) {
    return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
}

module.exports = formatDate;