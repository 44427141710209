import {React} from 'react';

import { BodyText, InlineLink } from "@hm-group/fabric-components/brand/hm";

export default function Footer() {
  return (
    <div className="footer">
        <BodyText style={{ textAlign: "center" }}>
          Need Help?{" "}
          <InlineLink
            style={{ color: "blue" }}
            href="https://hennesandmauritz.sharepoint.com/sites/PACorporateSupport/Shared%20Documents/PT%20External%20Communication/Showroom%20Application%20in%20Cloud_ExtCollabs/UserGuide/UserGuide_PPT%20version%20Ongoing.pdf?CT=1666893873415&OR=ItemsView"
            target="_blank"
          >
            Download user guide
          </InlineLink>
        </BodyText>
    </div>              
  ); 
}
